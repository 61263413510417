/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import Layout from 'components/partials/Layout';
import { Overflow } from 'components/partials/Overflow';
import { Col, Container } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Content = styled('div')(({ theme }) => ({
  '& h1': {
    ...theme.typography.h3,
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(0),
  },

  '& ol': {
    paddingLeft: theme.spacing(5),
  },

  '& p, ol': {
    ...theme.typography.body1,
    margin: theme.spacing(4.5, 0),
  },
  marginBottom: theme.spacing(20),
  marginTop: theme.spacing(5),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& h1': {
      ...theme.typography.h1,
      marginBottom: theme.spacing(16),
      marginTop: theme.spacing(0),
    },

    '& li': {
      ...theme.typography.body1,
    },

    '& ol, ul': {
      paddingLeft: theme.spacing(5),
    },

    '& p, ol, li': {
      margin: theme.spacing(4.5, 0),
    },

    marginBottom: theme.spacing(40),
    marginTop: theme.spacing(30),
  },
}));

const PageMain = styled('main')({
  position: 'relative',
});

const FreeContentTemplate: React.FC<Props> = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Overflow>
      <Layout title={frontmatter.title} description={frontmatter.description}>
        <PageMain>
          <Container>
            <Col md={3} />
            <Col md={6} sm={12}>
              <Content>
                <h1>{frontmatter.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </Content>
            </Col>
          </Container>
        </PageMain>
      </Layout>
    </Overflow>
  );
};

interface Props {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: MetaData;
    };
  };
}

export const pageQuery = graphql`
  query ($route: String!) {
    markdownRemark(frontmatter: { path: { eq: $route } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
  }
`;

export default FreeContentTemplate;
